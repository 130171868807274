import React from "react";
import { Spinner, Stack } from "react-bootstrap";
import { PositionSide, SupportedAsset } from '@perennial/sdk';
import { useMediaQuery } from "react-responsive";

import { MarketProvider, useMarketContext } from '../../contexts/marketContext'
import { useRefreshKeysOnPriceUpdates2 } from '../../hooks/markets2'

import TabsChart from "../TabsChart"
import TradeForm from "./TradeForm"
import Positions from "./Positions"
import { TradingViewWidget } from "./charts/TradingViewWidget"
import { MobileTradingViewWidget } from "./charts/MobileTradingViewWidget"
import { MarketInfoMetrics } from "../common/MarketMetrics"
import { usePushStream } from "../push/hooks";
import { LivePricesProvider } from "../../contexts/livePriceContext";
import { useCryptexProductsContext } from "../../contexts";
import Markets from "../../v1/pages/Markets"


const Trade = ({
  isProInterface,
  currentInterface,
  // setCurrentInterface
}: {
  isProInterface: boolean,
  currentInterface?: number,
  // setCurrentInterface?: (value: number) => void
  }) => {
  useRefreshKeysOnPriceUpdates2()
  usePushStream()

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const {
    selectedMarket,
    orderDirection,
    setOrderDirection,
    selectedMakerMarket,
    isMaker,
    snapshots2,
    userCurrentPosition,
  } = useMarketContext()
  const market = snapshots2?.market?.[isMaker ? selectedMakerMarket : selectedMarket]

  return(
    <>
      <TabsChart onTabChange={() => {}} />
      <div className={(!isMobile ? "trade-module" : "mobile-trade").concat(" trade-v2")}>
        <div className="trade-top">
          {isMobile && <MobileTradingViewWidget />}
          {!isMobile && (
            <div className="trade-left">
              <MarketInfoMetrics />
              <TradingViewWidget />
            </div>
          )}
          <div className="trade-right">
            {market ? (
              <Stack direction="vertical" gap={2}>
                <TradeForm
                  isNewPosition={true}
                  isProInterface={isProInterface}
                  asset={isMaker ? (selectedMakerMarket as SupportedAsset) : selectedMarket}
                  orderSide={isMaker ? PositionSide.maker : orderDirection}
                  setOrderDirection={setOrderDirection}
                  market={market}
                  position={userCurrentPosition}
                  showPositionAmount={true}
                />
              </Stack>  
            ) : (
              <div className="spinner-container trade">
                <Spinner animation="border" variant="primary" className="small" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="trade-bottom">
        <Positions isProInterface={isProInterface} />
      </div>
    </>  
  );
};

const TradeWrapper = ({
  isProInterface,
  currentInterface,
}: {
  isProInterface: boolean,
  currentInterface?: number,
}) => {
  const cryptexProducts = useCryptexProductsContext()
  
  return (
    <MarketProvider isMaker={isProInterface}>
      <LivePricesProvider>
        {cryptexProducts.isPerpetualsV1() ? (
          <Markets />
        ) : (
          <Trade isProInterface={isProInterface} currentInterface={currentInterface} />
        )}
      </LivePricesProvider>
    </MarketProvider>
  )
}


export default TradeWrapper;
