import React, { useMemo } from "react";
import { Badge, Button, Col, Card, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { mainnet } from "viem/chains";
import { FaEdit } from "react-icons/fa";

import CtxIcon from "../../../assets/ctx-icon.png";
import ethereumImg from "../../../assets/icons/eth.png";
import discordImg from "../../../assets/social-media/discord.png";
import xImg from "../../../assets/social-media/x_icon2.png";
import tallyImg from "../../../assets/social-media/tally.png";
import { VoteBadge, ProfileImage } from "./common";
import { ApiUrl, KeeperInfoType } from "../../../constants/governance";
import { shortenAddress } from "../../../utils/addressUtils";
import { useMediaQuery } from "react-responsive";
import { useAddress } from "../../../hooks/network";
import { useTranslation } from "react-i18next";


type props = {
  delegator: {
    id: string;
    delegatee: string;
    delegatedVotes: string;
    delegatedVotesRaw: string;
    tokenOwners: { stake: string; stakeRaw: string }[];
    totalHoldersRepresented: Number;
  };
  keeperInfo: KeeperInfoType;
  showUpdateKeeper: () => void;
  openStake: (currentDelegator: string) => void;
  openWithdraw: (keeperAddress: string, amountStaked: bigint) => void;
};

const ProfileCard = ({
  delegator,
  keeperInfo,
  showUpdateKeeper,
  openStake,
  openWithdraw,
}: props) => {
  const { t } = useTranslation()
  const { address } = useAddress()
  const isMobile = useMediaQuery({ query: "(max-width: 850px)" })

  const {userStaked, shortAddress, briefLength, ethName, why } = useMemo(() => {
    let userStaked = { stake: "0", stakeRaw: 0n };
    const briefLength = isMobile ? 143 : 200;

    if (delegator.tokenOwners && delegator.tokenOwners.length > 0) {
      userStaked = {
        stake: delegator.tokenOwners[0].stake,
        stakeRaw: BigInt(delegator.tokenOwners[0].stakeRaw),
      };
    }

    return {
      userStaked,
      shortAddress: shortenAddress(delegator.delegatee),
      briefLength,
      ethName: keeperInfo.eth_name.includes(".eth") ? keeperInfo.eth_name : shortenAddress(keeperInfo?.eth_name),
      why: keeperInfo.why.length <= briefLength ? keeperInfo.why : keeperInfo.why.slice(0, briefLength),
    };
  },
    [keeperInfo, isMobile, delegator]
  )


  const whyHtml = (why: string) => {
    const whyArray = why.split("\n\n");
    return (
      <>
        {whyArray.map((parragraph, index) => (
          <p key={index.toString()}>{parragraph}</p>
        ))}
      </>
    );
  };

  const copyDiscordToClipboard = (e: React.MouseEvent) => {
    e.preventDefault();
    // Create new element
    const el = document.createElement("textarea");
    // Set value (string to be copied)
    el.value = keeperInfo.discord;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand("copy");
    // Remove temporary element
    document.body.removeChild(el);
  };

  return (
    <Card key={delegator.id}>
      <div className="diamond" />
      <Card.Body>
        <ProfileImage
          address={delegator.delegatee}
          image={`${ApiUrl}${keeperInfo.image}`}
          size={25}
        />
        <Col md={12} lg={12} className="content">
          <Card.Title className="pb-2">
            <div className="title-names">
              <div className="title-row">
                <h5 className="mt-2">{keeperInfo.name}</h5>
                {delegator.delegatee === address?.toLowerCase() && (
                  <Button type="button" variant="outline-light" onClick={showUpdateKeeper}>
                    <FaEdit size={22} />
                  </Button>
                )}
              </div>
              <a
                href={`${mainnet.blockExplorers.default.url}/address/${delegator.delegatee}`}
                target="_blank"
                rel="noreferrer"
              >
                {ethName}
              </a>
            </div>
          </Card.Title>
          <div className="badges-container">
            <div className="badges-container2">
              {shortAddress && (
                <>
                  {userStaked.stakeRaw > 0n && (
                    <Badge bg="light">
                      <img src={CtxIcon} className="ctx-icon" alt="CTX logo" />
                      <span className="staked-label text-purple">
                        {parseFloat(userStaked.stake).toFixed(0) } {t("staked")}
                      </span>
                    </Badge>
                  )}
                  <VoteBadge
                    address={delegator.id}
                    amount={Math.round(parseFloat(delegator.delegatedVotes)).toString()}
                    label={t("votes")}
                  />
                  <VoteBadge
                    address={delegator.id}
                    amount={delegator.totalHoldersRepresented.toString()}
                    label={t("governance.respresented")}
                  />
                  <Badge bg="light">
                    <img src={tallyImg} className="tally" alt="tally logo" />
                    <a
                      href={`https://www.withtally.com/voter/${delegator.delegatee}/governance/cryptex`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("history")}
                    </a>
                  </Badge>
                  <Badge bg="light">
                    <img src={ethereumImg} className="ethereum" alt="ethereum logo" />
                    <a
                      href={`${mainnet.blockExplorers.default.url}/address/${delegator.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("contract")}
                    </a>
                  </Badge>
                </>
              )}
              <div className="accounts">
                {keeperInfo.discord && (
                  <OverlayTrigger
                    key="bottom"
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-bottom">{t("click-copy")}</Tooltip>}
                  >
                    <Badge pill bg="light">
                      <img src={discordImg} className="discord" alt="discord logo" />
                      <a href="/" onClick={copyDiscordToClipboard} className="address">
                        {keeperInfo.discord}
                      </a>
                    </Badge>
                  </OverlayTrigger>
                )}
                {keeperInfo.twitter && (
                  <Badge pill bg="light">
                    <img src={xImg} className="twitter" alt="twitter logo" />
                    <a
                      href={`https://twitter.com/${keeperInfo.twitter}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {keeperInfo.twitter}
                    </a>
                  </Badge>
                )}
              </div>
            </div>
          </div>
          <div className="why">
            <div className="why-title">
              <h5 className="mt-2">{t("governance.why-me")}</h5>
            </div>
            <OverlayTrigger
              key="auto"
              placement="auto"
              trigger={["hover", "click"]}
              overlay={
                <Tooltip id="tooltip-right" className="farm-tooltip">
                  {whyHtml(keeperInfo.why)}
                </Tooltip>
              }
            >
              <p>
                {why}
                {keeperInfo.why.length > briefLength && <span className="continue">...</span>}
              </p>
            </OverlayTrigger>
          </div>
          <div className="columns">
            <Col md={12} lg={12} className="content-col1">
              <div>
                <h5 className="mt-2">{t("governance.expertise")}</h5>
                <p>{keeperInfo.expertise}</p>
              </div>
            </Col>
          </div>
        </Col>
      </Card.Body>
      <Card.Footer>
        {address && (
          <Stack direction="horizontal" gap={2} className="pb-3 w-100">
            {userStaked.stakeRaw > 0n && (
              <Col xs={6} sm={6} md={6} lg={6}>
                <Button
                  className="mt-3 mb-2 w-100"
                  onClick={() => {
                    if (userStaked.stakeRaw > 0n) {
                      openWithdraw(delegator.id, userStaked.stakeRaw);
                    }
                  }}
                >
                  {t("governance.withdraw")}
                </Button>
              </Col>
            )}
            <Col
              xs={userStaked.stakeRaw > 0n ? 6 : 12}
              sm={userStaked.stakeRaw > 0n ? 6 : 12}
              md={userStaked.stakeRaw > 0n ? 6 : 12}
              lg={userStaked.stakeRaw > 0n ? 6 : 12}
            >
              <Button
                className="mt-3 mb-2 w-100"
                onClick={() => {
                  openStake(delegator.id);
                }}
              >
                Stake & Delegate
              </Button>
            </Col>
          </Stack>
        )}
      </Card.Footer>
    </Card>
  );
};

export default ProfileCard;
