import React, { useEffect, useMemo, useState } from "react"
import { Button, Dropdown, Form, Image, Row, Stack } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"
import { Big6Math, formatBig6USDPrice, calcNotional, ChainMarkets, SupportedAsset } from "@perennial/sdk"

import { useCryptexProductsContext, useLivePriceContext, useMarketContext } from '../../../contexts'
import { MarketMetadata,  } from "../../../constants/markets"
import { TcapMarketItem, TcapMarketToggle } from "../../../v1/components/TcapMarket"
import { TCAP_TOKEN } from "../../../v1/utils/constants"
import { CryptexProducts } from "../../../constants/network"


export const MarketsDropdown = ({ selectedLatestPrice }: { selectedLatestPrice: bigint } ) => {
  const { t } = useTranslation()
  const [marketFilter, setMarketFilter] = useState<string>("")
  const cryptexProducts = useCryptexProductsContext()
  const {
    chainId,
    isMaker,
    snapshots2,
    setSelectedMarket,
    selectedMarket,
    setSelectedMakerMarket,
    selectedMakerMarket,
  } = useMarketContext()
  const currentMarket = !isMaker ? selectedMarket : selectedMakerMarket

  const handleItemChange = (value: string | null) => {
    if (value) {
      if (cryptexProducts.isPerpetuals() && value !== TCAP_TOKEN.key) {
        if (isMaker) {
          setSelectedMakerMarket(value as SupportedAsset)
        } else { 
          setSelectedMarket(value as SupportedAsset)
        }
      } else if (cryptexProducts.isPerpetualsV1() && value !== TCAP_TOKEN.key) {
        if (isMaker) {
          setSelectedMakerMarket(value as SupportedAsset)
        } else { 
          setSelectedMarket(value as SupportedAsset)
        }
        cryptexProducts.setCurrentProduct(CryptexProducts.Perpetuals)
      } else if (cryptexProducts.isPerpetuals() && value === TCAP_TOKEN.key) {
        cryptexProducts.setCurrentProduct(CryptexProducts.PerpetualsV1)
      }
      setMarketFilter("")
    }
  };

  const [positiveChange, setPositiveChange] = useState(true)
  const [previousPrice, setPreviousPrice] = useState(0n)
  const livePrices = useLivePriceContext()
  const oraclePrice = livePrices[currentMarket as SupportedAsset]?.price ?? selectedLatestPrice
  const currentAssetMetadata = MarketMetadata[currentMarket]

  useEffect(() => {
    setPositiveChange(previousPrice < oraclePrice)
    setPreviousPrice(oraclePrice)
  },
    // eslint-disable-next-line
    [oraclePrice]
  )

  const sortedMarkets = useMemo(() => {
    const unsorted = Object.keys(ChainMarkets[chainId]).map((market) => {
      const assetMetadata = MarketMetadata[market as SupportedAsset]
      const marketSnapshot = snapshots2?.market?.[market as SupportedAsset]
      const marketPrice = marketSnapshot?.global?.latestPrice ?? 0n

      return {
        assetMetadata,
        marketPrice,
        makerNotional: calcNotional(marketSnapshot?.position?.maker ?? 0n, marketPrice)
      }
    })

    const filtered = marketFilter.trim() !== "" ? unsorted.filter((market) => {
      return market.assetMetadata.symbol.toLowerCase().includes(marketFilter)
        || market.assetMetadata.name.toLowerCase().includes(marketFilter)
    }) : unsorted;

    return filtered.sort((a, b) => {
      return Big6Math.toUnsafeFloat(b.makerNotional) - Big6Math.toUnsafeFloat(a.makerNotional)
    })
  },
    [chainId, snapshots2?.market, marketFilter]
  )

  const showTcapMarket = useMemo(() => {
    if (marketFilter.trim() === "") return true;
    return TCAP_TOKEN.key.toLowerCase().includes(marketFilter) || TCAP_TOKEN.description.toLowerCase().includes(marketFilter)
  }, [marketFilter])

  return (
    <Dropdown
      className="markets-dropdown"
      onSelect={(eventKey) => handleItemChange(eventKey)}
    >
      <Dropdown.Toggle id="dropdown-basic" variant="success">
        {cryptexProducts.isPerpetualsV1() ? (
          <TcapMarketToggle />
        ): (
          <div className="dropdown-toggle-container">
            <div className="toggle-section market">
              <Image src={currentAssetMetadata.icon} height={35} width={35} />
              <div className="market-desc">
                <h6 className="title">
                  {currentAssetMetadata.symbol}
                </h6>
                <span className="caption">{currentAssetMetadata.name}</span>
              </div>
            </div>
            <div className="vertical-divisor" />
            <div className="toggle-section price-box">
              <div className="flex-column">
                <span className={"price ".concat(positiveChange ? "text-green" : "text-red")}>
                  {formatBig6USDPrice(oraclePrice)}
                </span>
              </div>  
            </div>
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <>
          <Stack className="px-2 py-2">
            <Stack className="search-bar" direction="vertical">
              <Form.Control
                type="text"
                placeholder="Search Market"
                className="search-input"
                value={marketFilter}
                onChange={(event) => setMarketFilter(event.target.value.trim().toLowerCase())}
              />
              <Button variant="outline" className="search-cancel" onClick={() => setMarketFilter("")} >Clear</Button>
            </Stack>
          </Stack>
          <Row className="dropdown-menu-header">
            <span className="small text-muted">{t("market")}</span>
            <span className="small text-muted text-right">{t("price")} / {t("liquidity")}</span>
          </Row>
          {sortedMarkets.map((market, index) => {
            if (market.assetMetadata.isUnlisted) return <></>

            return (
              <>
                {index === 2 && showTcapMarket &&  (
                  <TcapMarketItem key={"ddi-tcap"} />
                )}
                <Dropdown.Item key={`${index}-${market.assetMetadata.symbol}`} eventKey={market.assetMetadata.baseCurrency}>
                  <Stack className="left" direction="horizontal">
                    <Image className="token-icon margin-right" src={market.assetMetadata.icon} height={30} width={30} />
                    <Stack>
                      <h6>{market.assetMetadata.symbol}</h6>
                      <span className="small text-muted">{market.assetMetadata.name}</span>
                    </Stack>
                  </Stack>
                  <Stack className="right">
                    <span className="number">
                      {formatBig6USDPrice(Big6Math.abs(market.marketPrice))}
                    </span>
                    <span className="number small text-muted">
                      {formatBig6USDPrice(market.makerNotional, {
                        compact: true,
                      })}
                    </span>
                  </Stack>
                </Dropdown.Item>
              </>  
            )
          })}
          {(sortedMarkets.length === 0 || sortedMarkets.length === 2) && showTcapMarket && (
            <TcapMarketItem key={"ddi-tcap"} />
          )}
        </>   
      </Dropdown.Menu>
    </Dropdown>
  )
};
