import React, { useEffect, useState } from "react"
import { Image, Stack } from "react-bootstrap/esm"
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "react-responsive";
import { Big6Math, formatBig6USDPrice, PositionSide, SupportedAsset } from "@perennial/sdk"

import { useCryptexProductsContext, useLivePriceContext, useMarketContext } from '../../../contexts'
import { NumericFormat } from "react-number-format"
import { MarketMetadata } from "../../../constants/markets"
import { MarketsDropdown } from "./MarketsDropdown"
import { useFormattedMarketBarValues } from "../../Trade/metrics/hooks"
import { capitalize } from "../../../utils/utils"
import { TcapMarketStats } from "../../../v1/components/TcapMarket";


type props = {
  isNewPosition: boolean
  orderDirection: PositionSide
  showStats: boolean
};

export const MarketInfo = ({ market, latestPrice, orderDirection }: { market: string, latestPrice: bigint, orderDirection: PositionSide }) => {
  const { t } = useTranslation()
  const assetMetadata = MarketMetadata[market as SupportedAsset];
  const [positiveChange, setPositiveChange] = useState(true)
  const [previousPrice, setPreviousPrice] = useState(0n)
  const livePrices = useLivePriceContext()
  const oraclePrice = livePrices[market as SupportedAsset]?.price ?? latestPrice

  useEffect(() => {
    setPositiveChange(previousPrice < oraclePrice)
    setPreviousPrice(oraclePrice)
  },
    // eslint-disable-next-line
    [oraclePrice]
  )

  return (
    <div className="markets-info">
      <Stack direction="horizontal">
        <Image src={assetMetadata.icon} height={35} width={35} />
        <div className="markets-desc">
          <h6 className="title">
            {assetMetadata.symbol}
          </h6>
          <span className="caption">
            {t(orderDirection).toUpperCase()} - USDC.e
          </span>
        </div>
      </Stack>  
      <div className="vertical-divisor" />
      <Stack style={{ justifyContent: "center" }}>
        <span
          className={"price ".concat(positiveChange ? "text-green" : "text-red")}
          style={{ textAlign: "center", fontSize: "1.4rem" }}
        >
          {formatBig6USDPrice(oraclePrice)}
        </span>
      </Stack>
    </div>
  )
};

export const MarketStats = (
  { selectedMarket }: { selectedMarket: string }
) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const { change, changeIsNegative, lpExposure, lpExposurePct } =  useFormattedMarketBarValues()

  return (
    <div className="markets-stats">
      <div className="stats-values">
        <div className="stats-item left">
          <span className="title small">
            {!isMobile ? `${t("vault-exposure")}:` : `${t("exposure")}:`}
          </span>
          <div className="stats-item-2">
            <NumericFormat
              className="number small"
              value={lpExposurePct}
              displayType="text"
              thousandSeparator=","
              decimalScale={4}
              suffix={"% ".concat(capitalize(lpExposure))}
            />
          </div>
        </div>
        <div className="stats-item right">
          <span className="title small">
            {t("metrics.24h-price-change")}:
          </span>
          <NumericFormat
            className={"number ".concat(changeIsNegative ? "text-red" : "text-green")}
            value={change}
            displayType="text"
            thousandSeparator=","
            suffix="%"
            decimalScale={2}
          />
        </div>
      </div>
    </div>
  )    
};

export const SelectMarkets = ({ isNewPosition, orderDirection, showStats }: props) => {
  const cryptexProducts = useCryptexProductsContext()
  const { isMaker, selectedMarket, selectedMakerMarket, selectedMarketSnapshot2 } = useMarketContext();
  const latestPrice = selectedMarketSnapshot2?.global?.latestPrice ?? 0n
  const currentMarket = !isMaker ? selectedMarket : selectedMakerMarket

  return (
    <div className="markets markets-v2">
      {isNewPosition ? (
        <MarketsDropdown selectedLatestPrice={Big6Math.abs(latestPrice)} />
      ) : (
        <MarketInfo market={currentMarket} latestPrice={latestPrice} orderDirection={orderDirection} />  
      )}
      {showStats && cryptexProducts.isPerpetuals() && (
        <MarketStats selectedMarket={currentMarket} />
      )}
      {showStats && cryptexProducts.isPerpetualsV1() && (
        <TcapMarketStats />
      )}
    </div>    
  );    
};
