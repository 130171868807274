import React, { useCallback, useMemo, useState } from "react";
import { Button, Dropdown, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQueryClient } from '@tanstack/react-query'
import { useChainId } from "wagmi";

import { useKeepers } from "../../hooks/governance/graph";
import ProfileCard from "./components/ProfileCard";
import { KeeperInfoType, KeeperType } from "../../constants/governance";
import { StakerStats } from "./components/StakerStats";
import Stake from "./components/Stake";
import { getAddress, zeroAddress } from "viem";
import Withdraw from "./components/Withdraw";
import KeeperForm from "./components/KeeperForm";
import { useCryptexProductsContext } from "../../contexts";


const Governance = () => {
  const { t } = useTranslation();
  const chainId = useChainId()
  const { isProductSupportedChain } = useCryptexProductsContext();
  const queryClient = useQueryClient();
  const sortOptions = [
    { key: "0", name: "Votes Descending" },
    { key: "1", name: "Votes Ascending" },
    { key: "2", name: "Delegates Descending" },
    { key: "3", name: "Delegates Ascending" },
  ];
  const [currentSorting, setCurrentSorting] = useState(0);
  const { data, isLoading: loadingKeepers } = useKeepers();
  const [showStake, setShowStake] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showKeeperForm, setShowKeeperForm] = useState(false);
  const [keeperIndex, setKeeperIndex] = useState(-1);
  const [currentKeeperAddress, setCurrentKeeperAddress] = useState(zeroAddress.toString());
  const [currentStaked, setCurrentStaked] = useState(0n);

  const refreshKeepers = useCallback(
    () =>
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          ['keepers'].includes(queryKey.at(0) as string) &&
          queryKey.includes(chainId),
      }),
    [chainId, queryClient],
  )

  const sortVotesDesc = (a: KeeperInfoType, b: KeeperInfoType) => b.delegatedVotes - a.delegatedVotes;

  const sortVotesAsc = (a: KeeperInfoType, b: KeeperInfoType) => a.delegatedVotes - b.delegatedVotes;

  const sortRepresentedDesc = (a: KeeperInfoType, b: KeeperInfoType) =>
    b.totalHoldersRepresented - a.totalHoldersRepresented;

  const sortRepresentedAsc = (a: KeeperInfoType, b: KeeperInfoType) =>
    a.totalHoldersRepresented - b.totalHoldersRepresented;

  const keepersFormattedInfo = useMemo(() => {
    const keepersFormattedInfo = data?.keepersFormattedInfo || [];
    if (currentSorting === 0) {
      keepersFormattedInfo.sort(sortVotesDesc);
    } else if (currentSorting === 1) {
      keepersFormattedInfo.sort(sortVotesAsc);
    } else if (currentSorting === 2) {
      keepersFormattedInfo.sort(sortRepresentedDesc);
    } else {
      keepersFormattedInfo.sort(sortRepresentedAsc);
    }

    return keepersFormattedInfo;
  }, [data, currentSorting])

  const getKeeperData = (address: string, currentKeepers: any): KeeperType | null => {
    const index = currentKeepers.findIndex(
      // @ts-ignore
      (item) => item.delegatee.toLowerCase() === address.toLowerCase()
    );
    if (index !== -1) {
      return currentKeepers[index];
    }
    return null;
  };

  const openWithdraw = (keeperAddress: string, ownerStake: bigint) => {
    setCurrentKeeperAddress(keeperAddress);
    setCurrentStaked(ownerStake);
    setShowWithdraw(true);
  };

  return (
    <div className="governance">
      <Row className="staker-wrapper">
        <StakerStats />
      </Row>
      <div className="keepers-options">
        <div className="search-box">
          <div className="sort-box">
            <h6 className="titles">Sort by:</h6>
            <Dropdown onSelect={(eventKey) => setCurrentSorting(parseInt(eventKey || "0"))}>
              <Dropdown.Toggle id="dropdown-filters" className="text-left">
                <h6>{sortOptions[currentSorting].name}</h6>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {sortOptions.map((item) => (
                  <Dropdown.Item key={item.key} eventKey={item.key}>
                    {item.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="create">
          <Button
            className="w-100"
            onClick={() => {
              setKeeperIndex(-1);
              setShowKeeperForm(true);
            }}
            disabled={!isProductSupportedChain(chainId)}
          >
            <>{t("governance.new")}</>
          </Button>
        </div>
      </div>
      {!loadingKeepers && data ? (
        <div className="grid profiles">
          {keepersFormattedInfo.map((keeperInfo, index) => {
            const keeper = getKeeperData(keeperInfo.address || "", data.keepers || []);
            if (keeper) {
              return (
                <ProfileCard
                  key={keeperInfo.id}
                  delegator={keeper}
                  keeperInfo={keeperInfo}
                  showUpdateKeeper={() => {
                    setKeeperIndex(index);
                    setShowKeeperForm(true);
                  }}
                  openStake={(keeperAddress: string) => {
                    setCurrentKeeperAddress(keeperAddress);
                    setShowStake(true);
                  }}
                  openWithdraw={openWithdraw}
                />
              );
            }
            return <></>;
          })}
        </div>
      ) : (
        <div></div>     
      )}
      {showStake && (
        <Stake
          show={showStake}
          keeperAddress={getAddress(currentKeeperAddress)}
          onHide={() => {
            setCurrentKeeperAddress(zeroAddress.toString())
            setShowStake(false)
          }}
        />
      )}
      {showWithdraw && (
        <Withdraw
          show={showWithdraw}
          keeperAddress={getAddress(currentKeeperAddress)}
          stakedAmount={currentStaked}
          onHide={() => {
            setCurrentKeeperAddress(zeroAddress.toString())
            setShowWithdraw(false)
          }}
        />
      )}
      {showKeeperForm && (
        <KeeperForm
          isNew={keeperIndex === -1}
          show={showKeeperForm}
          keepers={data?.keepers || []}
          keeperInfo={keeperIndex !== -1 ? keepersFormattedInfo[keeperIndex] : null}
          onHide={() => setShowKeeperForm(false)}
          refresh={() => refreshKeepers()}
        />
      )}
    </div>
  )    
}

export default Governance;