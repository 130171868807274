
import React from "react"
import { Stack } from "react-bootstrap";
// import Crypdex from "../../components/Crypdex";
import { CrypdexProvider } from "../../contexts/crypdexContext";


const CrypdexPage = () => (
  <CrypdexProvider>
    <Stack
      className="align-items-center justify-content-center h-100" style={{ marginTop: "-5.3rem" }}>
      <h1 className="text-purple" style={{ fontSize: "3.5rem" }}>Coming Soon</h1>
    </Stack>
  </CrypdexProvider>  
);

export default CrypdexPage;
