import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap/esm"
import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client"
import { Hour } from "@perennial/sdk"

import { WagmiProvider } from "wagmi"

import { CookiesProvider } from "react-cookie"
import axios from "axios"
import "./styles/app.scss"
import "react-toastify/dist/ReactToastify.css"
import "./styles/toast.scss"
import { LoadingPage } from "./components/common/"
import { CryptexProductsProvider, PushProvider } from "./contexts"
import { geoLocationContext, positionStatsContext, productContext } from "./v1/states"
import { useGeolocationContext, usePositionStatsContext, useProductContext } from "./v1/hooks"

import { ARBITRUM_GRAPHQL_URL } from "./v1/utils/constants";
import { wagmiConfig } from "./constants/network"
import { PerennialSDKProvider } from "./contexts/perennialSdkContext"
import Main from "./pages/Main"
import { mainnet } from "viem/chains"


const tanstackQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Number(1n * Hour) * 1000, // 1 hour in ms
    },
  },
})

const App = () => {
  const [isLoading, setLoading] = useState(true);
  const apolloClient = new ApolloClient({
    uri: ARBITRUM_GRAPHQL_URL,
    cache: new InMemoryCache(),
  });
  const geolocation = useGeolocationContext();
  const positionStats = usePositionStatsContext();
  const product = useProductContext();

  // Call the API to check if the user is allowed to access the beta
  // If the user is allowed, set isAllowed to true
  // If the user is not allowed, set isAllowed to false
  useEffect(() => {
    const loadGeoInfo = () => {
      axios.get("https://ipapi.co/json/").then((response) => {
        let data = response.data;
        geolocation.setCurrentCountryCode(data.country_code);
        setLoading(false);
      }).catch((error) => {
        axios.get("https://a3cxa9tir4.execute-api.us-east-1.amazonaws.com/country").then((response) => {
          geolocation.setCurrentCountryCode(response.data.country_code);
          setLoading(false);
        }).catch((error) => {
          console.log(error);
          setLoading(false);
        });
        setLoading(false);
      });
    };
    
    loadGeoInfo();
  },
    // eslint-disable-next-line
    []
  );

  if (isLoading) {
    return (
      <Container fluid className="wrapper transparent-background">
        <LoadingPage />
      </Container>
    );
  };

  return (
    <QueryClientProvider client={tanstackQueryClient}>
      <WagmiProvider config={wagmiConfig}>
        <RainbowKitProvider
          appInfo={{
            appName: "Cryptex Finance",
            learnMoreUrl: "https://docs.cryptex.finance/"
          }}
          initialChain={mainnet.id}
          theme={darkTheme({
            accentColor: "#A440F2",
            accentColorForeground: "white",
            borderRadius: "small",
            fontStack: "system",
            overlayBlur: "small",
          })}
          modalSize={"compact"}
          showRecentTransactions
        >
          <ApolloProvider client={apolloClient}>
            <PerennialSDKProvider>
              <PushProvider>
                <geoLocationContext.Provider value={geolocation}>
                  <productContext.Provider value={product}>
                    <positionStatsContext.Provider value={positionStats}>
                      <CryptexProductsProvider>
                        <CookiesProvider>
                          <Main />
                        </CookiesProvider>
                      </CryptexProductsProvider>
                    </positionStatsContext.Provider>        
                  </productContext.Provider>
                </geoLocationContext.Provider>
              </PushProvider>
            </PerennialSDKProvider>  
          </ApolloProvider>
        </RainbowKitProvider>
      </WagmiProvider>
    </QueryClientProvider>  
  );
}

export default App;
