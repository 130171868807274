import { ethers } from "ethers"
import { useWriteContract } from "wagmi"
import { getAddress } from "viem"
import { useTranslation } from "react-i18next"
import { buildInvokerAction, InvokerAction } from "../helpers"
import { multiInvokerContract } from "../utils/contracts"
import { useChainActions } from "../../hooks/network"
// import { getPublicClient } from "../../constants/network"
// import { arbitrum } from "viem/chains"


export const useVaultTransactions = (
  chainId: number,
  ownerAddress: string,
  vaultAddress: string,
  onSettled: (isSuccess: boolean, successMsg: string, error: any, tranHash: string) => void,
) => {
  const { t } = useTranslation()
  const { writeContract } = useWriteContract();
  const { onSwitchChain } = useChainActions()
  // const publicClient = getPublicClient(arbitrum.id)

  return ({
    onDeposit: async (collateralAmount: number) => {
      if (!(await onSwitchChain())) return
      
      const argsArray = new Array<{ action: number, args: string }>();
      const invokerArgs = buildInvokerAction(InvokerAction.VAULT_WRAP_AND_DEPOSIT, {
        userAddress: ownerAddress,
        productAddress: "",
        collateral: ethers.parseEther(collateralAmount.toString()),
        vaultAddress,
      })
      argsArray.push(invokerArgs)

      writeContract(
        {
          address: getAddress(multiInvokerContract[chainId].address),
          abi: multiInvokerContract.abi,
          functionName: "invoke",
          args: [argsArray],
        },
        {
          onSuccess(data) {
            onSettled(true, t("notification.vault-deposit"), null, data)
          },
          onError(error) {
            console.log(error)
            onSettled(false, "", error, "")
          },
        }
      )
    },
    onRedeem: async (sharesAmount: bigint) => {
      if (!(await onSwitchChain())) return

      const argsArray = new Array<{ action: number, args: string }>();
      const invokerArgs = buildInvokerAction(InvokerAction.VAULT_REDEEM,{
        userAddress: "",
        productAddress: "",
        collateral: sharesAmount,
        vaultAddress,
      });
      argsArray.push(invokerArgs)

      // console.log("argsArray: ", argsArray)
      try {
       /*  const { request } = await publicClient.simulateContract({
          address: getAddress(multiInvokerContract[chainId].address),
          abi: multiInvokerContract.abi,
          functionName: "invoke",
          args: [argsArray],
          account: "0xb45E2B252969394a48E46616378f19B1F1f32BdD",
        }) */

        // console.log("request: ", request)
        writeContract(
          {
            address: getAddress(multiInvokerContract[chainId].address),
            abi: multiInvokerContract.abi,
            functionName: "invoke",
            args: [argsArray],
          },
          {
            onSuccess(data) {
              onSettled(true, t("notification.vault-redeem"), null, data)
            },
            onError(error) {
              console.log("Redeem error: ", error)
              onSettled(false, "", error, "")
            },
          }
        )
      } catch (error) {
        console.log("Redeem error: ", error);
      }
    },
    onClaim: async (collateralAmount: bigint) => {
      if (!(await onSwitchChain())) return

      const params = {
        userAddress: ownerAddress,
        productAddress: "",
        collateral: collateralAmount,
        vaultAddress,
      };

      const invokerArgs = [
        buildInvokerAction(InvokerAction.VAULT_CLAIM, params),
        buildInvokerAction(InvokerAction.UNWRAP, params),
      ];

      writeContract(
        {
          address: getAddress(multiInvokerContract[chainId].address),
          abi: multiInvokerContract.abi,
          functionName: "invoke",
          args: [invokerArgs],
        },
        {
          onSuccess(data) {
            onSettled(true, t("notification.vault-claim"), null, data)
          },
          onError(error) {
            console.log("Claim error: ", error)
            onSettled(false, "", error, "")
          },
        }
      )
    }
  })
}
