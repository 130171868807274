import React from "react";
import ProvideLiquidity from "../../components/provide";
import { VaultProvider, useCryptexProductsContext } from "../../contexts";
import PWLiquidity from "../../v1/pages/PWLiquidity";

const ProvidePage = () => {
  const cryptexProducts = useCryptexProductsContext()
  
  return (
    <VaultProvider>
      {cryptexProducts.isPerpetuals() ? (
        <ProvideLiquidity />
      ) : (
        <PWLiquidity />
      )}
    </VaultProvider>
  )
};

export default ProvidePage
