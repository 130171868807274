import React, { useCallback, useState } from "react"
import { Button, Spinner } from "react-bootstrap/esm"
import { Address, getAddress } from "viem"
import { useQueryClient } from '@tanstack/react-query'
import { PositionSide, TriggerComparison } from "@perennial/sdk"

import { useMarketTransactions2 } from "../../../../hooks/markets2"
import { useTransactionToasts } from "../../../common"
import { OrderTypes } from "../../constants"
import { interfaceFeeBps } from "../../../../constants/network"
import { usePerpetualsChainId } from "../../../../hooks/network"


export const ModifyPositionButton = (
  { 
    label,
    marketAddress,
    positionSide,
    collateralDelta = 0n,
    positionAbs = 0n,
    interfaceFee = 0n,
    stopLossPrice = 0n,
    takeProfitPrice = 0n,
    settlementFee,
    disabled,
    successMessage,
    onSuccess,
  }: {
    label: string
    txHistoryLabel: string
    marketAddress: Address
    positionSide: PositionSide
    collateralDelta?: bigint
    positionAbs?: bigint
    interfaceFee?: bigint
    stopLossPrice?: bigint
    takeProfitPrice?: bigint
    settlementFee?: bigint
    disabled?: boolean,
    successMessage: string,
    onSuccess?: () => void,
  }) => {
    const chainId = usePerpetualsChainId()
    const [updating, setUpdating] = useState(false)
    const { waitForTransactionAlert } = useTransactionToasts()
    const { onModifyPosition } = useMarketTransactions2(marketAddress)
    const queryClient = useQueryClient()
    const refreshOrdersList = useCallback(
      () =>
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => ['openOrders'].includes(queryKey.at(0) as string) && queryKey.includes(chainId),
        }),
      [queryClient, chainId],
    )

    return (
      <Button
        className="btn-position"
        onClick={async () => { 
          setUpdating(true)
          const hash = await onModifyPosition({
            positionSide,
            positionAbs,
            collateralDelta,
            interfaceFee: {
              unwrap: true,
              receiver: getAddress(interfaceFeeBps[chainId].feeRecipientAddress),
              amount: interfaceFee,
            },
            stopLossPrice,
            takeProfitPrice,
          })
          if (hash) {
            await waitForTransactionAlert(hash, { successMsg: successMessage })
          }
          setUpdating(false)
          if (onSuccess) {
            onSuccess()
            refreshOrdersList()
          } 
        }}
        disabled={disabled || updating}
      >
        <div className="btn-spinner">
          {updating && <Spinner animation="border" variant="secondary" className="small" />}
          {label}
        </div>
      </Button>    
    )    
}


export const PlaceOrderButton = (
  { 
    label,
    marketAddress,
    orderType,
    positionSide,
    collateralDelta = 0n,
    positionAbs = 0n,
    limitPrice = 0n,
    takeProfitPrice = 0n,
    stopLossPrice = 0n,
    selectedLimitComparison,
    cancelOrderDetails,
    disabled,
    successMessage,
    onSuccess,
  }: {
    label: string
    marketAddress: Address
    orderType: OrderTypes
    positionSide: PositionSide
    collateralDelta?: bigint
    positionAbs?: bigint
    limitPrice?: bigint
    takeProfitPrice?: bigint
    stopLossPrice?: bigint
    selectedLimitComparison: TriggerComparison
    cancelOrderDetails?: {
      nonce: bigint
      market: Address
    }
    disabled?: boolean,
    successMessage: string,
    onSuccess?: () => void,
  }) => {
    const chainId = usePerpetualsChainId()
    const [updating, setUpdating] = useState(false)
    const { waitForTransactionAlert } = useTransactionToasts()
    const { onPlaceOrder } = useMarketTransactions2(marketAddress)
    
    const queryClient = useQueryClient()
    const refreshOrdersList = useCallback(
      () =>
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => ['openOrders'].includes(queryKey.at(0) as string) && queryKey.includes(chainId),
        }),
      [queryClient, chainId],
    )
  
    return (
      <Button
        className="btn-position"
        onClick={async () => { 
          setUpdating(true)
          const hash = await onPlaceOrder({
            orderType,
            limitPrice,
            takeProfitPrice,
            stopLossPrice,
            side: positionSide,
            collateralDelta,
            delta: 0n,
            // positionAbs,
            triggerComparison: selectedLimitComparison,
            cancelOrderDetails: cancelOrderDetails ? [cancelOrderDetails] : undefined,
          })
          if (hash) {
            await waitForTransactionAlert(hash, { successMsg: successMessage })
          }
          setUpdating(false)
          if (onSuccess) { 
            onSuccess()
            refreshOrdersList()
          } 
        }}
        disabled={disabled || updating}
      >
        <div className="btn-spinner">
          {updating && <Spinner animation="border" variant="secondary" className="small" />}
          {label}
        </div>
      </Button>    
    )    
}
